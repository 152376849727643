
    import NotificationComponent from '@/modules/notifications/components/notifications/shared/notification-component';
    import Component from 'vue-class-component';
    import NotificationBase from './shared/notification-base.vue';
    import NotificationMinifiedBase from './shared/notification-minified-base.vue';

    @Component({
        components: {
            NotificationBase,
            NotificationMinifiedBase,
        },
    })
    export default class CreatedNotification extends NotificationComponent {}
