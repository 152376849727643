export const notificationsChannels = [
    // { id: 'browser', name: 'Przeglądarka' },
    // { id: 'mobile', name: 'Telefon' },
    { id: 'mail', name: 'E-mail' },
    { id: 'app', name: 'Aplikacja' },
];
export const notificationTypesTranslations = [
    { id: 'commentCreated', name: 'Nowy komentarz' },
    { id: 'reminder', name: 'Przypomnienie' },
    { id: 'updated', name: 'Edycja' },
    { id: 'created', name: 'Stworzenie' },
    { id: 'propertyUpdated', name: 'Zmiana właściwości' },
];
export const notificationSettingsTranslations = [
    { id: 'created_notification', name: 'Utworzenie' },
    { id: 'deleted_notification', name: 'Usunięcie' },
    { id: 'comment_created_notification', name: 'Dodanie komentarza' },
    { id: 'one_day_before_reminder', name: 'Dzień przed' },
    { id: 'one_day_after_reminder', name: 'Dzień po' },
    { id: 'today_reminder', name: 'Dzisaj' },
    { id: 'updated_assigned_user_notification', name: 'Przypisany użytkownik' },
    { id: 'updated_priority_notification', name: 'Priorytet' },
    { id: 'updated_status_notification', name: 'Status' },
];

export const polishModuleNames = [
    { id: 'acceptance', name: 'Odbiór', namePlural: 'Odbiory' },
    { id: 'cost', name: 'Koszt inwestycji', namePlural: 'Koszty Inwestycji' },
    { id: 'sale', name: 'Lead sprzedażowy', namePlural: 'Leady Sprzedażowe' },
    { id: 'investment_subject', name: 'Przedmiot inwestycji', namePlural: 'Przedmioty inwestycji' },
    { id: 'ticket', name: 'Zadanie', namePlural: 'Zadania' },
];

export const salePolishPropertyNames = [
    { id: 'sale_status_id', nominative: 'Status', accusative: 'Status' },
    { id: 'sale_status', nominative: 'Status', accusative: 'Status' },
    { id: 'assigned_user', nominative: 'Opiekun', accusative: 'Opiekuna' },
    { id: 'assigned_user_id', nominative: 'Opiekun', accusative: 'Opiekuna' },
    { id: 'name', nominative: 'Imię i nazwisko', accusative: 'Imię i nazwisko' },
    { id: 'email', nominative: 'E-mail', accusative: 'E-mail' },
    { id: 'phone', nominative: 'Numer telefonu', accusative: 'Numer telefonu' },
    { id: 'investment', nominative: 'Inwestycja', accusative: 'Inwestycje' },
    { id: 'investment_subject', nominative: 'Przedmiot Inwestycji', accusative: 'Przedmiot inwestycji' },
    { id: 'question', nominative: 'Pytanie', accusative: 'Pytanie' },
    { id: 'source', nominative: 'Źródło', accusative: 'Źródłó' },
    { id: 'due_date', nominative: 'Termin', accusative: 'Termin' },
];

export const ticketPolishPropertyNames = [
    { id: 'ticket_status_id', nominative: 'Status', accusative: 'Status' },
    { id: 'status', nominative: 'Status', accusative: 'Status' },
    { id: 'assigned_user', nominative: 'Osoba przypisana', accusative: 'Osobę przypisaną' },
    { id: 'assigned_user_id', nominative: 'Osoba przypisana', accusative: 'Osobę przypisaną' },
    { id: 'name', nominative: 'Nazwa zgłoszenia', accusative: 'Nazwę zgłoszenia' },
    { id: 'ticket_priority_id', nominative: 'Priorytet', accusative: 'Priorytet' },
    { id: 'category_id', nominative: 'Kategoria', accusative: 'Kategorie' },
    { id: 'tags', nominative: 'Tagi', accusative: 'Tagi' },
    { id: 'description', nominative: 'Opis', accusative: 'Opis' },
    { id: 'due_date', nominative: 'Termin', accusative: 'Termin' },
];

export const costPolishPropertyNames = [
    { id: 'number', nominative: 'Nr Dokumentu', accusative: 'Nr dokumentu' },
    { id: 'status', nominative: 'Status', accusative: 'Status' },
    { id: 'contractor_id', nominative: 'Kontrahent', accusative: 'Kontrahenta' },
    { id: 'type_id', nominative: 'Rodzaj', accusative: 'Rodzaj' },
    { id: 'issue_date', nominative: 'Data wystawienia', accusative: 'Datę wystawnienia' },
    { id: 'sale_date', nominative: 'Data sprzedaży', accusative: 'Datę sprzedaży' },
    { id: 'due_date', nominative: 'Termin płatności', accusative: 'Termin płatności' },
    { id: 'paid_at', nominative: 'Data opłacenia', accusative: 'Datę opłacenia' },
    { id: 'payment_method', nominative: 'Rodzaj płatności', accusative: 'Rodzaj płatności' },
];

export const acceptancePolishPropertyNames = [
    { id: 'investment_subject', nominative: 'Przedmiot inwestycji', accusative: 'Przedmiot inwestycji' },
    { id: 'status', nominative: 'Status', accusative: 'Status' },
    { id: 'assigned_user_id', nominative: 'Osoba przypisana', accusative: 'Osobę przypisaną' },
    { id: 'name', nominative: 'Nazwa odbioru', accusative: 'Nazwę odbioru' },
    { id: 'description', nominative: 'Opis', accusative: 'Opis' },
    { id: 'reference_photo', nominative: 'Zdjęcie referencyjne', accusative: 'Zdjęcie referencyjne' },
];

export const investmentSubjectPolishPropertyNames = [
    { id: 'name', nominative: 'Nazwa', accusative: 'Nazwę' },
    { id: 'code', nominative: 'Identyfikator', accusative: 'Identyfikator' },
    { id: 'investment_subject_type', nominative: 'Typ', accusative: 'Typ' },
    { id: 'description', nominative: 'Opis', accusative: 'Opis' },
    { id: 'price', nominative: 'Cena', accusative: 'Cenę' },
    { id: 'public_status', nominative: 'Status publiczny', accusative: 'Status publiczny' },
    { id: 'private_status', nominative: 'Status prywatny', accusative: 'Status prywatny' },
    { id: 'land', nominative: 'Grunt', accusative: 'Grunt' },
    { id: 'show_price', nominative: 'Pokaż cenę', accusative: 'Pokaż cenę' },
    {
        id: 'show_price_per_meter',
        nominative: 'Pokaż cene za m<sup>2</sup>',
        accusative: 'Pokaż cene za m<sup>2</sup>',
    },
    { id: 'investment_object', nominative: 'Kategoria inwestycji', accusative: 'Kategorie inwestycji' },
    { id: 'adress', nominative: 'Adres', accusative: 'Adres' },
    {
        id: 'location_of_apartment_in_building',
        nominative: 'Położenie lokalu mieszkalnego w budynku',
        accusative: 'Położenie lokalu mieszkalnego w budynku',
    },
    {
        id: 'determination_of_position_standart',
        nominative: 'Standard wykończenia przedmiotu inwestycji',
        accusative: 'Standard wykończenia przedmiotu inwestycji',
    },
    {
        id: 'way_of_subject_measurement',
        nominative: 'Sposób pomiaru powierzchni lokalu mieszkalnego lub domu jednorodzinnego',
        accusative: 'Sposób pomiaru powierzchni lokalu mieszkalnego lub domu jednorodzinnego',
    },
    {
        id: 'trust_account_number',
        nominative: 'Numer rachunku powierniczego',
        accusative: 'Numer rachunku powierniczego',
    },
    {
        id: 'transfer_of_ownership_date',
        nominative: 'Ostateczny termin przeniesiena praw własności',
        accusative: 'Ostateczny termin przeniesiena praw własności',
    },
];


