import { render, staticRenderFns } from "./chip-property.vue?vue&type=template&id=1af43860&scoped=true&"
import script from "./chip-property.vue?vue&type=script&lang=ts&"
export * from "./chip-property.vue?vue&type=script&lang=ts&"
import style0 from "./chip-property.vue?vue&type=style&index=0&id=1af43860&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1af43860",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VChip,VIcon,VImg,VTooltip})
