import { render, staticRenderFns } from "./property-updated.vue?vue&type=template&id=65cec949&scoped=true&"
import script from "./property-updated.vue?vue&type=script&lang=ts&"
export * from "./property-updated.vue?vue&type=script&lang=ts&"
import style0 from "./property-updated.vue?vue&type=style&index=0&id=65cec949&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65cec949",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
