
    import NotificationComponent from '@/modules/notifications/components/notifications/shared/notification-component';
    import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';
    import LongTextProperty from './shared/long-text-property.vue';
    import NotificationBase from './shared/notification-base.vue';
    import NotificationMinifiedBase from './shared/notification-minified-base.vue';

    @Component({
        components: {
            NotificationBase,
            NotificationMinifiedBase,
            LongTextProperty,
            TooltipBtn,
        },
    })
    export default class CommentCreatedNotification extends NotificationComponent {
        @Prop(Boolean) public commentPermitted!: boolean;
        public showTextarea = false;
        public newComment = '';

        public toggleTextarea() {
            this.showTextarea = !this.showTextarea;
        }
        public include() {
            return [(this.$refs.btn as Vue).$el];
        }

        public addComment() {
            this.$emit('addComment', this.newComment);
            this.showTextarea = false;
            this.newComment = '';
        }

        public closeTextarea() {
            this.showTextarea = false;
            this.newComment = '';
        }
    }
