import CreatedNotification from '@/modules/notifications/components/notifications/created.vue';
import UpdatedNotification from '@/modules/notifications/components/notifications/updated.vue';
import CommentCreatedNotification from '@/modules/notifications/components/notifications/comment-created.vue';
import PropertyUpdatedNotification from '@/modules/notifications/components/notifications/property-updated.vue';
import ReminderNotification from '@/modules/notifications/components/notifications/reminder.vue';
import DeletedNotification from '@/modules/notifications/components/notifications/deleted.vue';
import { costStatuses } from '@/shared/config/costs';
import { acceptancesStatuses } from '@/shared/config/acceptances';
import { privateStatuses, publicStatuses } from '@/shared/config/investments';

export const notificationsComponents = {
    created: CreatedNotification,
    updated: UpdatedNotification,
    deleted: DeletedNotification,
    commentCreated: CommentCreatedNotification,
    propertyUpdated: PropertyUpdatedNotification,
    reminder: ReminderNotification,
};

export const frontendBasedProperties = {
    cost: {
        status: costStatuses ,
    },
    acceptance: {
        status: acceptancesStatuses,
    },
    investment_subject: {
        private_status: privateStatuses,
        public_status: publicStatuses,
    }

};
