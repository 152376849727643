
import { User } from '@/modules/auth/shared/models/user';
import { parseDateTime } from '@/shared/helpers';
import moment from 'moment';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

import { notificationsComponents } from '../../../shared/config/notifications';

@Component({
    components: {
        ...notificationsComponents,
    },
})
export default class NotificationMinifiedBase extends Vue {
    @Prop(Object) public item!: any;
    @Prop({ type: Boolean, default: false }) public elementDeleted!: boolean;
    @Prop({ type: Boolean, default: false }) public brandNew!: boolean;
    @Prop({ type: Boolean, default: false }) public unexpandable!: boolean;
    @Prop(String) public createdAt!: string;
    @Prop(String) public moduleName!: string;
    @Prop(String) public elementName!: string;
    @Prop(Object) public initiator!: User;
    @Prop(String) public color!: string;
    @Prop(String) public icon!: string;

    get subtitle() {
        return `${this.moduleName} - ${this.elementName}`;
    }

    public moment = moment;
    public parseDateTime = parseDateTime;

    get date() {
        return moment(this.createdAt);
    }
}
