
    import { frontendBasedProperties } from '@/modules/notifications/shared/config/notifications';
    import TextContrast from '@/shared/components/elements/text-contrast.vue';
    import { parseDate, parsePrice } from '@/shared/helpers';
    import { hexToRgb, contrast } from '@/shared/helpers/colors';
    import Vue from 'vue';
    import Component from 'vue-class-component';
    import { Prop } from 'vue-property-decorator';

    @Component({
        components: {
            TextContrast,
        },
    })
    export default class ChipProperty extends Vue {
        @Prop([Object, String, Number]) public itemModel!: any;
        @Prop([Object, String, Number]) public itemValue!: any;
        @Prop(String) public propertyName!: string;
        @Prop(String) public type!: string;
        @Prop(Boolean) public isDate!: boolean;
        @Prop(Boolean) public isPrice!: boolean;
        @Prop(String) public moduleName!: string;

        public frontendBasedProperties = frontendBasedProperties;
        public whiteColor = hexToRgb('#ffffff');
        public blackColor = hexToRgb('#000000');

        get model() {
            const purePropertName = this.propertyName
                .replace(this.type + '_', '')
                .replace('_id', '') as keyof typeof moduleFrontedProperites;

            const moduleFrontedProperites = frontendBasedProperties[this.type as keyof typeof frontendBasedProperties];

            if (moduleFrontedProperites && moduleFrontedProperites[purePropertName]) {
                const valuesList = moduleFrontedProperites[purePropertName] as any[];
                return valuesList.find((el) => el.id === this.itemValue || el.value === this.itemValue);
            }
            return this.itemModel;
        }

        get isDark() {
            if (!this.color) {
                return false;
            }
            return contrast(hexToRgb(this.color), this.whiteColor) > 2;
        }

        get isLight() {
            if (!this.color) {
                return false;
            }
            return contrast(hexToRgb(this.color), this.blackColor) > 2;
        }

        get isDataDeleted() {
            return !this.model && !!this.itemValue;
        }

        get text() {
            if (this.isDataDeleted) {
                return 'Zawartość usunięta';
            }

            if (!this.model) {
                return 'Brak';
            }

            switch (typeof this.model) {
                case 'string':
                    if (this.isDate) {
                        return parseDate(this.model);
                    } else {
                        return this.model;
                    }
                case 'number':
                    if (this.isPrice) {
                        return parsePrice(this.model);
                    } else {
                        return this.model.toString();
                    }
                default:
                    return this.model.name;
            }
        }

        get hasAvatar() {
            return this.model && this.model.hasOwnProperty('avatar_photo');
        }

        get hasIcon() {
            if (this.isDataDeleted) {
                return true;
            }
            return this.model && this.model.hasOwnProperty('icon');
        }

        get icon() {
            if (this.isDataDeleted) {
                return 'mdi-alert';
            } else if (!this.model || !this.hasIcon) {
                return '';
            } else {
                if (typeof this.model.icon === 'string') {
                    return this.model.icon;
                } else {
                    return this.model.icon.slug;
                }
            }
        }

        get color() {
            if (this.isDataDeleted) {
                return 'warning';
            } else if (!this.model || typeof this.model !== 'object') {
                return '';
            } else if (this.model.hasOwnProperty('color')) {
                return this.model.color;
            }
            return '';
        }
    }
