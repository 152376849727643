
import NotificationComponent from '@/modules/notifications/components/notifications/shared/notification-component';
import Component from 'vue-class-component';
import NotificationBase from './shared/notification-base.vue';
import moment from 'moment';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import { Prop } from 'vue-property-decorator';
import NotificationMinifiedBase from './shared/notification-minified-base.vue';

@Component({
    components: {
        NotificationBase,
        NotificationMinifiedBase,
        TooltipBtn,
    },
})
export default class ReminderNotification extends NotificationComponent {
    @Prop(Boolean) public editPermitted!: boolean;
    public layout!: { [key: string]: any };

    get deadline() {
        if (!this.item) {
            return moment();
        } else {
            return moment(this.item.deadline).startOf('day');
        }
    }

    get differenceInDays() {
        return this.deadline.diff(this.date, 'days');
    }
    get chipText() {
        switch (this.differenceInDays) {
            case 0:
                return 'Dzisaj';
            case 1:
                return 'Jutro';
            case -1:
                return 'Wczoraj';
            default:
                let text = this.date.locale('pl-Pl').to(this.deadline);
                text = text[0].toUpperCase() + text.slice(1);
                return text;
        }
    }
    get subtitleText() {
        if (this.differenceInDays === 0) {
            return 'Dzisaj upływa termin';
        } else if (this.differenceInDays > 0) {
            return 'Termin niedługo upłynie';
        } else {
            return 'Termin upłynął';
        }
    }

    get color() {
        if (this.differenceInDays === 0) {
            return 'error';
        } else if (this.differenceInDays > 0) {
            return 'warning';
        } else {
            return 'red darken-4';
        }
    }

    public created() {
        this.layout = this.getLayout(this.item, '');
    }
}
