
import { User } from '@/modules/auth/shared/models/user';
import TooltipBtn from '@/shared/components/elements/tooltip-btn.vue';
import moment from 'moment';
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
@Component({
    components: {
        TooltipBtn,
    },
})
export default class NotificationBase extends Vue {
    @Prop(String) public createdAt!: string;
    @Prop(Object) public initiator!: User;
    @Prop({ type: Boolean, default: false }) public read!: boolean;
    @Prop({ type: Boolean, default: false }) public elementDeleted!: boolean;
    @Prop({type: Boolean, default: false}) public inUnreadView !: boolean;


    get dateFormat() {
        return this.$vuetify.breakpoint.lgAndUp ? 'HH:mm, dddd, DD MMMM YYYY' : 'HH:mm, DD MMM YYYY';
    }

    get date() {
        return moment(this.createdAt).locale('pl-PL').format(this.dateFormat);
    }
}
