import { User } from '@/modules/auth/shared/models/user';
import {
    polishModuleNames,
} from '@/shared/config/notifications';
import { parseDate } from '@/shared/helpers';
import moment from 'moment';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';

@Component
export default class NotificationComponent extends Vue {
    @Prop([Object, Array]) public item!: any;
    @Prop(Object) public element!: any;
    @Prop(String) public elementName!: string;
    @Prop(String) public elementType !: string;
    @Prop(String) public createdAt!: string;
    @Prop(Object) public initiator!: User;
    @Prop({ type: Boolean, default: false }) public minified !: boolean;
    @Prop({ type: Boolean, default: false }) public read !: boolean;
    @Prop({ type: Boolean, default: false }) public inUnreadView !: boolean;
    @Prop({ type: Boolean, default: false }) public elementDeleted !: boolean;

    public parseDate = parseDate;

    get date() {
        return moment(this.createdAt).locale('pl-PL');
    }

    get moduleName() {
        return polishModuleNames.find((el) => el.id === this.elementType)?.name || this.elementType;
    }

    public getPropertyType(property: any) {
        switch (typeof property) {
            case 'string':
                if (this.isHTML(property)) {
                    return 'longText';
                }
                break;
            default:
                return 'chip';
        }
    }

    public getComponent(property: any) {
        switch (this.getPropertyType(property)) {
            case 'longText':
                return 'LongTextProperty';
            default:
                return 'ChipProperty';
        }
    }

    public isDate(property: any) {
        if (typeof property !== 'string') {
            return false;
        }
        return moment(property, moment.ISO_8601).isValid();
    }

    public isHTML(property: string) {
        return /<(?=.*? .*?\/ ?>|br|hr|input|!--|wbr)[a-z]+.*?>|<([a-z]+).*?<\/\1>/i.test(property);
    }

    public getLayout(property1: any, property2: any) {
        const firstComponent = this.getComponent(property1);
        const secondComponent = this.getComponent(property2);

        if (firstComponent === 'ChipProperty' && secondComponent === 'ChipProperty') {
            return {
                wrapper: 'flex-wrap flex-sm-nowrap flex-column flex-sm-row justify-center justify-sm-start',
                firstComponent: 'ml-sm-2 mt-2 mt-sm-0',
                secondComponent: 'mb-1 mb-sm-0',
                icon: ''
            };
        } else if (firstComponent === 'LongTextProperty' && secondComponent === 'ChipProperty') {
            return {
                wrapper: 'flex-wrap justify-center justify-sm-start',
                firstComponent: 'my-sm-2 mb-1 flex-basis-100',
                secondComponent: 'mt-2 mb-1 mb-sm-0',
                icon: 'flex-basis-100 d-flex justify-center justify-sm-start '

            };
        } else if (firstComponent === 'ChipProperty' && secondComponent === 'LongTextProperty') {
            return {
                wrapper: 'flex-wrap justify-center justify-sm-start',
                firstComponent: ' ml-sm-2 ',
                secondComponent: 'mt-2 flex-basis-100 mb-1 mb-sm-0',
                icon: ''

            };
        } else {
            return {
                wrapper: 'flex-wrap justify-center justify-sm-start',
                firstComponent: 'my-sm-2 mb-1',
                secondComponent: 'mt-2 mb-1 mb-sm-0',
                icon: 'flex-basis-100'

            };

        }
    }
}
