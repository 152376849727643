
import NotificationComponent from '@/modules/notifications/components/notifications/shared/notification-component';
import Component from 'vue-class-component';
import ChipProperty from './shared/chip-property.vue';
import LongTextProperty from './shared/long-text-property.vue';
import NotificationBase from './shared/notification-base.vue';

@Component({
    components: {
        NotificationBase,
        ChipProperty,
        LongTextProperty,
    },
})
export default class UpdatedNotification extends NotificationComponent {
    get longTexts() {
        const arr: boolean[] = [];
        if (!this.item) {
            return arr;
        }
        this.item.property_names.forEach((_: any, index: number) => {
            arr.push(this.isAnyPropertyLongText(this.item.old_properties[index], this.item.new_properties[index]));
        });
        return arr;
    }

    get layouts() {
        const arr: any[] = [];
        if (!this.item) {
            return arr;
        }
        this.item.property_names.forEach((_: any, index: number) => {
            arr.push(this.getLayout(this.item.old_properties[index], this.item.new_properties[index]));
        });
        return arr;
    }

    public isPrice(propertyName: string) {
        return propertyName === 'price';
    }

    public isAnyPropertyLongText(prop1: any, prop2: any) {
        if (!this.item) {
            return false;
        }
        return this.getPropertyType(prop1) === 'longText' || this.getPropertyType(prop2) === 'longText';
    }
}
