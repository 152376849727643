
import NotificationComponent from '@/modules/notifications/components/notifications/shared/notification-component';
import {
    acceptancePolishPropertyNames,
    costPolishPropertyNames,
    investmentSubjectPolishPropertyNames,
    salePolishPropertyNames,
    ticketPolishPropertyNames,
} from '@/shared/config/notifications';
import Component from 'vue-class-component';
import ChipProperty from './shared/chip-property.vue';
import LongTextProperty from './shared/long-text-property.vue';
import NotificationBase from './shared/notification-base.vue';
import NotificationMinifiedBase from './shared/notification-minified-base.vue';

@Component({
    components: {
        NotificationBase,
        NotificationMinifiedBase,
        ChipProperty,
        LongTextProperty,
    },
})
export default class PropertyUpdatedNotification extends NotificationComponent {
    public layout!: { [key: string]: any };

    public polishProperties: { [key: string]: any[] } = {
        sale: salePolishPropertyNames,
        cost: costPolishPropertyNames,
        ticket: ticketPolishPropertyNames,
        investment_subject: investmentSubjectPolishPropertyNames,
        acceptance: acceptancePolishPropertyNames,
    };

    get polishPropertName() {
        if (!this.item) {
            return '';
        }
        return this.getPolishPropertyName(this.item.property_name, true);
    }

    get isAnyPropertyLongText() {
        if (!this.item) {
            return false;
        }
        return (
            this.getPropertyType(this.item.new_model) === 'longText' ||
            this.getPropertyType(this.item.old_model) === 'longText'
        );
    }

    get isPrice() {
        return this.item.property_name === 'price';
    }

    public getPolishPropertyName(property: string, accusative: boolean = false) {
        if (this.polishProperties[this.elementType.toLocaleLowerCase()]) {
            const polishTranslations = this.polishProperties[this.elementType.toLocaleLowerCase()].find(
                (el: any) => el.id === property,
            );

            if (polishTranslations) {
                return polishTranslations[accusative ? 'accusative' : 'nominative'];
            } else {
                return property;
            }
        } else {
            return '';
        }
    }

    public created() {
        this.layout = this.getLayout(this.item.old_model, this.item.new_model);
    }
}
